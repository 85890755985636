import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'schweisserei',
    pathMatch: 'full'
  },
  {
    path: 'prognose',
    loadChildren: () => import('./prognose/prognose.module').then( m => m.PrognosePageModule)
  },
  {
    path: 'schweisserei',
    loadChildren: () => import('./schweisserei/schweisserei.module').then( m => m.SchweissereiPageModule)
  },
  {
    path: 'waescherei',
    loadChildren: () => import('./waescherei/waescherei.module').then( m => m.WaeschereiPageModule)
  },
  {
    path: 'montage',
    loadChildren: () => import('./montage/montage.module').then( m => m.MontagePageModule)
  },
  {
    path: 'ruecklieferung',
    loadChildren: () => import('./ruecklieferung/ruecklieferung.module').then( m => m.RuecklieferungPageModule)
  },
  {
    path: 'gesamt',
    loadChildren: () => import('./gesamt/gesamt.module').then( m => m.GesamtPageModule)
  },
  {
    path: 'kgsbhm',
    loadChildren: () => import('./kgsbhm/kgsbhm.module').then( m => m.KgsbhmPageModule)
  },
  {
    path: 'import',
    loadChildren: () => import('./import/import.module').then( m => m.ImportPageModule)
  },


];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
